import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import FeaturePararaph from '../components/feature-paragraph'

import FreeTrialButton from '../components/free-trial-button';

import {KGlackinTestimonial} from "../components/testimonial";

import savingsSrc from '../images/savings.svg';
import supportSrc from '../images/support.svg';
import warningSrc from '../images/warning.svg';
import fairUseSrc from '../images/fair-use.svg';
import switchToTkSrc from '../images/switch-to-tk.svg';
import clockInSrc from '../images/clockin.png';


const ClockSharkAlternative = () => (
  <Layout>
    <SEO 
    	title="The Best ClockShark Alternative is TimeKeeper"
    	description="Switch to TimeKeeper, the modern, UK based ClockShark Alternative"
    	 />
    <Navbar />
    <div className="bg-gray-50">
		  <div className="relative bg-white overflow-hidden">
			  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
			    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
			      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
			        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
			          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
			            Make the switch from <span style={{ color: '#00487A' }}>ClockShark</span> <br />
			          </h2>

			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			            Looking for an alternative to ClockShark’s time and attendance system? TimeKeeper is a UK based alternative to ClockShark that we think is simpler, friendlier and cheaper.
			          </p>

			          <FreeTrialButton />
			        </div>
			        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
			          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
			            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
			              <img className="w-full" src={switchToTkSrc} alt="ClockShark vs TimeKeeper" />
			            </button>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="py-8 bg-gray-50 overflow-hidden lg:py-24">
			  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
				     
				     <RightFeature 
				    	sectionTitle="Switching will save you money" 
				    	title="Affordable pricing, with no base fees" 
				    	body={
				    		<div>
						        <FeaturePararaph>
						          With TimeKeeper, you’ll simply pay for each employee account you add, with no base fees or minimum charge. Each employee account costs just £3.50 ex VAT per month, with full access to all of our time and attendance features, as well as leave management, job tracking and HR features included too.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	Things not working out? Your monthly subscription can be cancelled any time, without paying a penalty.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={savingsSrc}
				    	imgAlt="TimeKeeper Cheaper Alternative to ClockShark"
				    />
				    <LeftFeature 
				    	sectionTitle="Verify your employee clock ins" 
				    	title="Biometric Clock In"
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          TimeKeeper has this ability along with the option to perform facial recognition on clock in to ensure the right employee is clocking in. Supported on Kiosk and Mobile.
						        </FeaturePararaph>
						        <FeaturePararaph>
						           This eliminates the problem of buddy clock ins' in your construction business. 
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={clockInSrc}
				    	imgAlt="Photo Clock In Support"
				     />
				    <RightFeature 
				    	sectionTitle="24/7 Support" 
				    	title="Friendly, Real-Time Customer Support"
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          Our Customer Success team are here to help during UK working hours via live chat and video call, to answer your questions and give advice on your set up.
						        </FeaturePararaph>
						        <FeaturePararaph>
						         	If it's outside of working hours, we have a full help-centre available too at help.timekeeper.co.uk! So if you need some help, we’ll always be on hand to make your transition as smooth as possible.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={supportSrc}
				    	imgAlt="Real Time Support"
				     />
				     <LeftFeature 
				    	sectionTitle="No Background Location Tracking" 
				    	title="Friendly Fair Use for Employees" 
				    	body={
				    		<div>
						        <FeaturePararaph>
						          Our GPS-enabled mobile app helps to verify your employees’ timesheets, whilst respecting their privacy. 
						        </FeaturePararaph>
						        <FeaturePararaph> 
						        	We simply record their location when clocking in or out. TimeKeeper will never ask for permission to track your location in the background. 
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={fairUseSrc}
				    	imgAlt="TimeKeeper "
				    />
				    <KGlackinTestimonial />
				</div>
				<FreeTrial part1={(<span>Save <span className="text-highlight-600">thousands today</span> moving from ClockShark <br /> to TimeKeeper!</span>)} />
			</div>
		</div>
		<Footer/>
  </Layout>
)

export default ClockSharkAlternative;